import { TextField } from '@mui/material';

function TokenInputField({
    value,
    rows,
    onChange,
}: {
    value: string;
    rows: number;
    onChange: (v: string) => void;
}) {
    return (
        <TextField
            sx={{
                '& .MuiFormLabel-root.Mui-disabled': {
                    color: 'black',
                },
                '& .MuiInputBase-input': {
                    textFillColor: 'black !important',
                },
            }}
            label="Token"
            variant="outlined"
            fullWidth={true}
            multiline={true}
            rows={rows}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            inputProps={{
                style: {
                    fontSize: 'smaller',
                },
            }}
            InputProps={{
                style: {
                    height: '100%',
                    alignItems: 'baseline',
                    flexDirection: 'column',
                },
            }}
        ></TextField>
    );
}

export default TokenInputField;
