/* eslint-disable @typescript-eslint/no-explicit-any */
import { filterKey, parseJws, verifySignature } from './crypto-lib';
import {
    getJwks,
    getJwksViaProxy,
    getOpenIdConfig,
    getOpenIdConfigViaProxy,
} from './jwtParserService';
import { TKey, TTokenValidationResult } from './types';

export const runtime = 'edge';

export class SignedToken {
    token: string;
    header = '';
    payload = '';
    keys: TKey[] = [];
    results: TTokenValidationResult[] = [];
    stopProcessing = false;
    constructor(token: string) {
        this.token = token;
    }
    pushToResult(isSuccess: boolean, msg: string) {
        this.results.push({ isSuccess, msg });
    }
    async doProcess(): Promise<TTokenValidationResult[]> {
        this.parseToken();
        await this.readJwks();
        this.validateSignature();
        this.validateExpiry();
        return this.results;
    }
    parseToken() {
        const result = parseJws(this.token);
        if (!result) {
            this.stopProcessing = true;
            console.log('Unable to parse the token');
            this.pushToResult(false, 'Parsing Error');
        } else {
            this.header = result.header;
            this.payload = result.payload;
        }
    }
    async readJwks() {
        if (this.stopProcessing) return;
        const iss = JSON.parse(this.payload)['iss'];
        if (!iss) {
            this.stopProcessing = true;
            console.log('Issuer claim not found');
            this.pushToResult(false, 'Issuer');
            return;
        }
        let isBrowserCallWorked = true;
        let jwksUri = '';
        let configResult = await getOpenIdConfig(iss);
        if (typeof configResult === 'number') {
            isBrowserCallWorked = false;
            console.log(
                'Error getting openid-config from browser, trying backend'
            );
            configResult = await getOpenIdConfigViaProxy(iss);
        }

        if (typeof configResult === 'number') {
            this.stopProcessing = true;
            console.log('Error reading openid-configration');
            this.pushToResult(false, 'OpenidConfiguration');
        } else {
            jwksUri = configResult['jwks_uri'];
            console.log(`jwks_uri: ${jwksUri}`);
            let jwksResult: any | number = '';
            if (isBrowserCallWorked) {
                jwksResult = await getJwks(jwksUri);
            } else {
                jwksResult = await getJwksViaProxy(jwksUri);
            }
            if (typeof jwksResult === 'number') {
                this.stopProcessing = true;
                console.log('JWKS not found');
                this.pushToResult(false, 'JWKS');
            } else {
                this.pushToResult(true, 'JWKS');
                this.keys = jwksResult.keys;
            }
        }
    }
    validateSignature() {
        if (this.stopProcessing) return;
        const parsedHeader = JSON.parse(this.header);
        const x5t = parsedHeader['x5t'];
        const kid = parsedHeader['kid'];
        let signatureResult = false;
        if (x5t || kid) {
            let key = undefined;
            if (x5t) {
                key = filterKey(x5t, 'x5t', this.keys);
            } else {
                key = filterKey(kid, 'kid', this.keys);
            }
            if (key) {
                console.log(`Key found: ${key}`);
                this.pushToResult(true, 'Key');
                signatureResult = verifySignature(key, this.token);
            } else {
                this.stopProcessing = true;
                console.log('Key not found in JWKS');
                this.pushToResult(false, 'Key');
                return;
            }
        } else {
            console.log('Token header does not have x5t or kid');
            this.stopProcessing = true;
            this.pushToResult(false, 'Key');
        }
        if (signatureResult) {
            console.log('Valid signature');
            this.pushToResult(true, 'Signature');
        } else {
            this.stopProcessing = true;
            console.log('Signature failed');
            this.pushToResult(false, 'Signature');
        }
    }
    validateExpiry() {
        if (this.stopProcessing) return;
        const exp = JSON.parse(this.payload)['exp'] as number;
        const unixEpochMs = exp * 1000;
        const nowTime = new Date();
        const expTime = new Date(unixEpochMs);
        console.log('Token Expiry', expTime);
        console.log('Current Time', nowTime);
        if (nowTime < expTime) {
            this.pushToResult(true, 'Not Expired');
        } else {
            this.stopProcessing = true;
            this.pushToResult(false, 'Expired');
        }
    }
}
