/* eslint-disable @typescript-eslint/no-explicit-any */
import { fetchWithTimeout } from './util';

export async function getOpenIdConfig(issuer: string): Promise<any | number> {
    if (issuer.endsWith('/')) {
        issuer = issuer.slice(0, -1);
    }
    if (!issuer.startsWith('http')) {
        issuer = `https://${issuer}`;
    }
    const apiPath = `${issuer}/.well-known/openid-configuration`;
    console.log(`Reading openid-configuration ${apiPath}`);
    try {
        const response = await fetchWithTimeout(apiPath, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (response.ok) {
            console.log('Success reading openid-configuration');
            const apiResponse = await response.json();
            return apiResponse;
        } else {
            const errMsg = await response.json();
            console.log(`Error reading openid-configuration ${errMsg}`);
            return 999;
        }
    } catch (err) {
        console.log(`Error reading openid-configuration. ${err}`);
        return 999;
    }
}

export async function getOpenIdConfigViaProxy(
    issuer: string
): Promise<any | number> {
    if (issuer.endsWith('/')) {
        issuer = issuer.slice(0, -1);
    }
    if (!issuer.startsWith('http')) {
        issuer = `https://${issuer}`;
    }
    const apiPath = `${issuer}/.well-known/openid-configuration`;
    console.log(`Reading openid-configuration via Proxy ${apiPath}`);
    try {
        const proxyPath = `/api/get-proxy?target=${apiPath}`;
        const response = await fetchWithTimeout(proxyPath, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (response.ok) {
            console.log('Success reading openid-configuration');
            const apiResponse = await response.json();
            return apiResponse;
        } else {
            const errMsg = await response.json();
            console.log(`Error reading openid-configuration ${errMsg}`);
            return 999;
        }
    } catch (err) {
        console.log(`Error reading openid-configuration. ${err}`);
        return 999;
    }
}

export async function getJwks(apiPath: string): Promise<any | number> {
    try {
        const response = await fetchWithTimeout(apiPath, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (response.ok) {
            console.log('Success reading jwks');
            const apiResponse = await response.json();
            return apiResponse;
        } else {
            const errMsg = await response.json();
            console.log(`Error reading jwks ${errMsg}`);
            return 999;
        }
    } catch (err) {
        console.log(`Error reading jwks. ${err}`);
        return 999;
    }
}

export async function getJwksViaProxy(apiPath: string): Promise<any | number> {
    const proxyPath = `/api/get-proxy?target=${apiPath}`;
    try {
        const response = await fetchWithTimeout(proxyPath, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (response.ok) {
            console.log('Success reading jwks');
            const apiResponse = await response.json();
            return apiResponse;
        } else {
            const errMsg = await response.json();
            console.log(`Error reading jwks ${errMsg}`);
            return 999;
        }
    } catch (err) {
        console.log(`Error reading jwks. ${err}`);
        return 999;
    }
}
