import { useState } from 'react';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
} from '@mui/material';
import TokenInputField from './TokenInputField';
import TokenParsedField from './TokenParsedField';
import { parseJws } from '../../lib/crypto-lib';
import { SignedToken } from '../../lib/SignedToken';
import { TTokenValidationResult } from '../../lib/types';

function TokenParser(): React.ReactElement {
    const [headerCopyStatus, setHeaderCopyStatus] = useState('Copy');
    const [payloadCopyStatus, setPayloadCopyStatus] = useState('Copy');
    const [isParsed, setIsParsed] = useState(false);
    const [token, setToken] = useState('');
    const [header, setHeader] = useState('');
    const [payload, setPayload] = useState('');
    const [results, setResults] = useState<TTokenValidationResult[]>([]);
    const [isValidating, setIsValidating] = useState(false);

    function handleTokenInput(v: string) {
        setResults([]);
        setToken(v);
        const parsed = parseJws(v);
        if (parsed) {
            setIsParsed(true);
        } else {
            setIsParsed(false);
        }
        setHeader(parsed?.header ?? '');
        setPayload(parsed?.payload ?? '');
    }

    async function handleValidateToken() {
        setResults([]);
        setIsValidating(true);
        const signedToken = new SignedToken(token);
        const results = await signedToken.doProcess();
        setResults([...results]);
        setIsValidating(false);
    }

    return (
        <Container sx={{ marginTop: 4 }}>
            <Grid container spacing={3}>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{ display: 'flex', position: 'relative' }}
                >
                    <TokenInputField
                        value={token}
                        rows={27}
                        onChange={handleTokenInput}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{
                        display: 'flex',
                        position: 'relative',
                        flexDirection: 'column',
                    }}
                >
                    <TokenParsedField
                        label="Header"
                        rows={6}
                        value={header}
                        color="red"
                        copyStatus={headerCopyStatus}
                        setCopyStatus={setHeaderCopyStatus}
                    />
                    <Box mb={2} />
                    <TokenParsedField
                        label="Payload"
                        rows={16}
                        value={payload}
                        color="green"
                        copyStatus={payloadCopyStatus}
                        setCopyStatus={setPayloadCopyStatus}
                    />
                </Grid>
            </Grid>
            <Box pb={2} />
            {!isParsed && token !== '' && (
                <Alert sx={{ justifyContent: 'center' }} severity="error">
                    Unable to parse the token.
                </Alert>
            )}
            {isParsed && !isValidating && (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        sx={{ minWidth: 300 }}
                        variant="contained"
                        onClick={handleValidateToken}
                    >
                        Validate
                    </Button>
                </Box>
            )}
            {isParsed && isValidating && (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        disabled={true}
                        variant="contained"
                        sx={{ width: 300 }}
                    >
                        <CircularProgress
                            size={18}
                            color="info"
                            sx={{ marginRight: 2 }}
                        />
                        Validating...
                    </Button>
                </Box>
            )}

            <Box pb={2} />
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                {results.map((v, i) => (
                    <Alert
                        variant="filled"
                        sx={{ margin: 1 }}
                        key={i}
                        severity={v.isSuccess ? 'success' : 'error'}
                    >
                        {v.msg}
                    </Alert>
                ))}
            </Box>
        </Container>
    );
}

export default TokenParser;
