import { ContentCopyTwoTone } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';

function TokenParsedField({
    label,
    rows,
    value,
    color,
    copyStatus,
    setCopyStatus,
}: {
    label: string;
    rows: number;
    value: string;
    color: string;
    copyStatus: string;
    setCopyStatus: (s: string) => void;
}) {
    return (
        <TextField
            sx={{
                '& .MuiFormLabel-root.Mui-disabled': { color: color },
                '& .MuiInputBase-input': {
                    textFillColor: `${color} !important`,
                },
            }}
            label={label}
            variant="outlined"
            fullWidth={true}
            multiline={true}
            rows={rows}
            disabled={true}
            value={value}
            inputProps={{
                style: {
                    fontSize: 'smaller',
                },
            }}
            InputProps={{
                style: {
                    height: '100%',
                    alignItems: 'baseline',
                    flexDirection: 'column',
                },
                startAdornment: (
                    <InputAdornment
                        position="start"
                        sx={{
                            marginTop: 1,
                            marginBottom: 3,
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            navigator.clipboard
                                .writeText(value)
                                .then(() => setCopyStatus('Copied'));
                            setTimeout(() => setCopyStatus('Copy'), 5000);
                        }}
                    >
                        <ContentCopyTwoTone sx={{ color: color }} />
                        <span
                            style={{
                                fontSize: 'smaller',
                                color: 'black',
                                marginLeft: '8px',
                            }}
                        >
                            {copyStatus}
                        </span>
                    </InputAdornment>
                ),
            }}
        ></TextField>
    );
}

export default TokenParsedField;
