export async function fetchWithTimeout(apiPath: string, options = {}) {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), 5000);
    const response = await fetch(apiPath, {
        ...options,
        signal: controller.signal,
    });
    clearTimeout(id);
    return response;
}

export function getRandomString(): string {
    return `${Date.now().toString(36)}${Math.random()
        .toString(36)
        .substring(2, 10)}`;
}
