import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Appbar from './components/AppBar/Appbar';
import Error from './components/Error/Error';
import TokenParser from './components/JwtParser/TokenParser';

function AppRouter(): React.ReactElement {
    return (
        <BrowserRouter>
            <Appbar />
            <Routes>
                <Route path="/" element={<TokenParser />} />
                <Route
                    path="/*"
                    element={
                        <Error
                            errorcode="404"
                            title="Page Not Found"
                            message="We don't have what you looking for."
                        />
                    }
                />
            </Routes>
        </BrowserRouter>
    );
}

export default AppRouter;
