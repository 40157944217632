/* eslint-disable @typescript-eslint/no-explicit-any */
import $ from 'jsrsasign';
import { TKey, TParsedJws } from './types';

export function parseJws(token: string): TParsedJws | undefined {
    try {
        const decoded = $.KJUR.jws.JWS.parse(token);
        return {
            header: decoded.headerPP,
            payload: decoded.payloadPP,
        };
    } catch (err) {
        console.error(`Error decrypting token. ${err}`);
        return undefined;
    }
}

export function filterKey(
    key: string,
    keyType: string,
    keys: TKey[]
): TKey | undefined {
    if (keyType == 'x5t') {
        const certs = keys.filter((i) => i.x5t === key);
        if (certs.length === 1) return certs[0];
    }
    if (keyType == 'kid') {
        const certs = keys.filter((i) => i.kid === key);
        if (certs.length === 1) return certs[0];
    }
    return undefined;
}

export function verifySignature(key: TKey, jwt: string): boolean {
    const pubkey = $.KEYUTIL.getKey(key);
    const isValid = $.KJUR.jws.JWS.verify(jwt, pubkey as any);
    return isValid;
}

export function tryVerifySignature(keys: TKey[], jwt: string) {
    let isValid = false;
    for (let i = 0; i < keys.length; i++) {
        const r = verifySignature(keys[i], jwt);
        if (r) {
            console.log(`Validated using key. ${keys[i]}`);
            isValid = true;
            break;
        }
    }
    return isValid;
}

export async function getAes256CbcKey(): Promise<CryptoKey> {
    const key = await window.crypto.subtle.generateKey(
        {
            name: 'AES-CBC',
            length: 256,
        },
        true,
        ['decrypt', 'encrypt']
    );

    return key;
}

export async function exportAes256CbcKey(
    key: CryptoKey
): Promise<string | undefined> {
    const exportedKey = await window.crypto.subtle.exportKey('jwk', key);
    return exportedKey.k;
}

export async function importAes256CbcKey(key: string): Promise<CryptoKey> {
    const base64Key = key.replaceAll('-', '+').replaceAll('_', '/');

    const rawKey = Uint8Array.from(atob(base64Key), (c) => c.charCodeAt(0));

    const importedKey = await window.crypto.subtle.importKey(
        'raw',
        rawKey,
        'AES-CBC',
        true,
        ['decrypt', 'encrypt']
    );

    return importedKey;
}

export async function encryptMessage(
    message: string,
    key: CryptoKey,
    iv: Uint8Array
): Promise<string> {
    const buffer = new TextEncoder().encode(message);

    const cipherArrayBuffer = await window.crypto.subtle.encrypt(
        {
            name: 'AES-CBC',
            iv,
        },
        key,
        buffer
    );

    return arrayBufferToBase64(cipherArrayBuffer);
}

export async function decryptMessage(
    message: string,
    key: CryptoKey,
    iv: string
): Promise<string> {
    const cipherArrayBuffer = base64ToArrayBuffer(message);

    const ivUint8Array = base64ToUint8Array(iv);

    const buffer = await window.crypto.subtle.decrypt(
        {
            name: 'AES-CBC',
            iv: ivUint8Array,
        },
        key,
        cipherArrayBuffer
    );

    const decryptedMessage = new TextDecoder().decode(buffer);

    return decryptedMessage;
}

export function arrayBufferToBase64(buffer: ArrayBuffer): string {
    const uint8Array = new Uint8Array(buffer);
    return uint8ArrayToBase64(uint8Array);
}

export function uint8ArrayToBase64(uint8Array: Uint8Array): string {
    let binaryString = '';

    for (let i = 0; i < uint8Array.byteLength; i++) {
        binaryString += String.fromCharCode(uint8Array[i]);
    }

    const b64String = btoa(binaryString);

    return b64String;
}

export function base64ToArrayBuffer(base64: string): ArrayBuffer {
    const uint8Array = base64ToUint8Array(base64);
    return uint8Array.buffer;
}

export function base64ToUint8Array(base64: string): Uint8Array {
    const binaryString = atob(base64);

    const bytes = new Uint8Array(binaryString.length);

    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes;
}
